import styled from "styled-components";

const StyledDisplay = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .image-container {
    transition: 0.3s;
    height: 100%;
  }

  .component-container {
    transition: 0.3s;
  }

  img {
    height: 100%;
  }
`;
export default StyledDisplay;
