import styled from "styled-components";

const StyledNavbar = styled.div`
  padding-top: 1rem;
  padding-left: 2.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  user-select: none;

  .logo-container {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
  }

  .logo-container:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  .logo {
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize.xxl};
    color: ${({ theme }) => theme.colors.primaryLight};
    transition: opacity 0.2s ease-in-out;
  }

  .logo-img {
    width: 38px;
    margin-left: -1rem;
    position: absolute;
  }

  .logo-text-container {
    margin-left: 28px;
  }

  .logo-start {
    font-weight: 600;
  }

  .logo-end {
    font-weight: 400;
  }

  .logo-stamp {
    margin-top: -0.25rem;
    margin-right: -0.5rem;
    text-align: right;
    font-size: ${({ theme }) => theme.fontSize.s};
    color: ${({ theme }) => theme.colors.text};
    font-weight: 600;
  }
  .logo-stamp-company {
    font-family: "Kanit", sans-serif;
  }

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn {
    padding: 0.25rem;
    margin-left: 1rem;
    color: ${({ theme }) => theme.colors.text};
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  .btn:hover {
    /* text-decoration: underline; */
    color: ${({ theme }) => theme.colors.primary};
  }

  .btn-text {
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.m};
  }

  .theme-toggle {
    margin-left: 1rem;
    display: flex;
  }

  .toggle-text {
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.m};
  }

  .toggle-icon {
    cursor: pointer;
    margin-left: -0.15rem;
    padding: 0.25rem;
    color: ${({ theme }) => theme.colors.text};
    background-color: transparent;
    border: none;
    font-size: ${({ theme }) => theme.fontSize.xxl};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .btn-toggle-icon {
    font-size: ${({ theme }) => theme.fontSize.l};
  } */

  .btn-icon {
    display: none;
    font-size: ${({ theme }) => theme.fontSize.l};
  }

  @media screen and (max-width: 600px) {
    .btn-text {
      display: none;
    }

    .btn-icon {
      display: block;
    }
  }
`;
export default StyledNavbar;
