import styled from "styled-components";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25rem;

  .text-container {
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-size: clamp(
      ${({ theme }) =>
        theme.fontSize.xl + ", -0.875rem + 8.333vw, " + theme.fontSize.xxxl}
    );
    color: ${({ theme }) => theme.colors.text};
  }

  .input-container {
  }
`;

export default StyledHeader;
