import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import StyledNotification from "./styles/StyledNotification.styled";

const Notification = ({ notification }) => {
  const {
    text = "",
    isError = false,
    isPermanent = false,
    onDestroy = null,
  } = notification;
  const [flip, setFlip] = useState(false);

  const destroyHandler = () => {
    if (onDestroy) {
      if (flip) onDestroy();
      else setFlip(true);
    }
  };

  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
    delay: 400,
    reverse: flip,
    onRest: destroyHandler,
  });

  return (
    <StyledNotification>
      <animated.div style={!isPermanent ? props : null}>
        <span className={isError ? " error" : ""}>{text}</span>
      </animated.div>
    </StyledNotification>
  );
};

export default Notification;
