import styled from "styled-components";

const StyledStepIndicator = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;

  .container {
  }
  .icon {
    font-size: ${({ theme }) => theme.fontSize.m};
    color: ${({ theme }) => theme.colors.text};
  }
  .active {
    color: ${({ theme }) => theme.colors.primaryLight};
  }
`;
export default StyledStepIndicator;
