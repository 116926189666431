import React from "react";
import StyledHeader from "./styles/StyledHeader.styled";

const Header = ({ header }) => {
  return (
    <StyledHeader>
      <div className="text-container">{header}</div>
    </StyledHeader>
  );
};

export default Header;
