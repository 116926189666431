import React from "react";
import { useOutletContext } from "react-router-dom";
import Button from "../components/Button";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Screen from "../containers/Screen";

const Privacy = () => {
  const { navigate } = useOutletContext();
  return (
    <Screen
      header="Privacy Policy"
      childComponent={<PrivacyPolicy />}
      buttons={
        <Button text="Home" onClick={() => navigate("/", { replace: true })} />
      }
    />
  );
};

export default Privacy;
