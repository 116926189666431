const fontSize = {
  xs: "0.64rem",
  s: "0.8rem",
  m: "1rem",
  l: "1.25rem",
  xl: "1.563rem",
  xxl: "1.953rem",
  xxxl: "2.441rem",
};

const light = {
  isDarkMode: false,
  colors: {
    primary: "#008080",
    primaryLight: "#00a3a3",
    text: "#212121",
    textSecondary: "#686868",
    inActive: "#DDDDDD",
    background: "#FFFFFF",
    transparent: "#0000ff00",
    red: "#F02D3A",
    black: "#212121",
    white: "#FFFFFF",
  },
  fontSize,
};

const dark = {
  isDarkMode: true,
  colors: {
    primary: "#008080",
    primaryLight: "#00a3a3",
    text: "#DDDDDD",
    textSecondary: "#B7B7B7",
    inActive: "#828282",
    background: "#121212",
    transparent: "#0000ff00",
    red: "#F02D3A",
    black: "#212121",
    white: "#FFFFFF",
  },
  fontSize,
};

export const themes = { light, dark };
