import React from "react";
import { useOutletContext } from "react-router-dom";

import StyledDisplay from "./styles/StyledDisplay.styled";

/* Loads all images */
const imagesLight = require.context("../assets/img/light", true);
const imagesDark = require.context("../assets/img/dark", true);

const Display = ({ imageName, childComponent }) => {
  /* Loads specific image */
  const { theme } = useOutletContext();
  const imageSelect = theme.isDarkMode ? imagesDark : imagesLight;
  let image, display;
  if (imageName) {
    image = imageSelect("./" + imageName);
    display = (
      <div className="image-container">
        <img src={image} alt="catapult" />
      </div>
    );
  } else {
    display = <div className="component-container">{childComponent}</div>;
  }

  return <StyledDisplay>{display}</StyledDisplay>;
};

export default Display;
