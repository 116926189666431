import React from "react";
import { useOutletContext } from "react-router-dom";
import StyledQRcode from "./styles/StyledQRcode.styled";
import QRCode from "react-qr-code";

const QRcode = ({ value }) => {
  const { theme } = useOutletContext();
  return (
    <StyledQRcode>
      <QRCode
        value={value}
        bgColor="transparent"
        fgColor={theme.isDarkMode ? theme.colors.inActive : theme.colors.text}
      />
    </StyledQRcode>
  );
};

export default QRcode;
