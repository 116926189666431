import styled from "styled-components";

const StyledDrawer = styled.div`
  width: 260px;
  height: 100vh;
  position: fixed;
  transition: right 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.primaryLight};

  .container {
    margin: 1rem;
  }

  .btn-close {
    width: fit-content;
    padding: 0.75rem;
    margin-left: auto;
    margin-right: 0;
    font-size: ${({ theme }) => theme.fontSize.xl};
    color: ${({ theme }) => theme.colors.primary};
    transition: color 0.15s ease;
    cursor: pointer;
    user-select: none;
  }

  .btn-close:hover {
    color: ${({ theme }) => theme.colors.white};
  }

  .links {
    margin-top: 3rem;
    margin-left: 0.75rem;
  }

  .link {
    height: 50px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    transition: color 0.15s ease;
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .link-icon {
    width: 1.5rem;
  }

  .link-text {
    margin-left: 0.75rem;
  }

  .link-text:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default StyledDrawer;
