import styled from "styled-components";

const StyledInfo = styled.div`
  min-height: 16rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSize.l};
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
  height: 100%;

  .container {
    margin-top: 1.5rem;
    text-align: left;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .highlight {
    color: ${({ theme }) => theme.colors.primaryLight};
  }

  .icon {
    margin-right: 0.75rem;
  }
`;
export default StyledInfo;
