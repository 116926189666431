import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Button from "../components/Button";
import StyledInfo from "../components/styles/StyledInfo.styled";
import Screen from "../containers/Screen";
import StepIndicator from "../components/StepIndicator";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faLink,
  faLaptop,
  faMobileScreenButton,
  faTv,
  faRightLong,
  faLeftLong,
  faUser,
  faCircleInfo,
  faKey,
  faSmileBeam,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faCircleCheck,
  faLink,
  faLaptop,
  faMobileScreenButton,
  faTv,
  faRightLong,
  faLeftLong,
  faUser,
  faCircleInfo,
  faKey,
  faSmileBeam
);

const Tutorial = () => {
  const { navigate } = useOutletContext();
  const [stepIndex, setStepIndex] = useState(0);

  const stepIndicator = (
    <StepIndicator totalSteps={7} currentStep={stepIndex} />
  );

  const nextHandler = () => {
    if (stepIndex !== steps.length - 1) setStepIndex(stepIndex + 1);
  };

  const backHandler = () => {
    if (stepIndex !== 0) setStepIndex(stepIndex - 1);
    else navigate("/", { replace: true });
  };

  const backButton = (
    <Button
      text={stepIndex === 0 ? "Exit" : "Back"}
      cancel={true}
      onClick={backHandler}
    />
  );

  const buttons = (
    <>
      <Button text="Next" outline={true} onClick={nextHandler} />
      {backButton}
    </>
  );

  const firstStep = (
    <Screen
      header="What is webpult?"
      childComponent={
        <StyledInfo>
          <div className="container">
            <span className="highlight">
              <b>web</b>pult
            </span>{" "}
            is a web app that allows users to redirect websites on another
            device.
            <br />
            <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="circle-check" />
              No account, download, or devices on same network required.
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={buttons}
    />
  );

  const secondStep = (
    <Screen
      header="Who is the Sender?"
      childComponent={
        <StyledInfo>
          <div className="container">
            <b>Sender</b> is the device that sets the website used to redirect
            other devices a.k.a the Passenger.
            <br /> <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="user" />
              <FontAwesomeIcon className="icon" icon="right-long" />
              <FontAwesomeIcon className="icon" icon="tv" />
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={buttons}
    />
  );

  const thirdStep = (
    <Screen
      header="What does Sender do?"
      childComponent={
        <StyledInfo>
          <div className="container">
            <span className="highlight">
              <b>1.</b>
            </span>
            &nbsp;&nbsp;As the <b>Sender</b>, you’ll first be asked for a
            website URL.
            <br />
            <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="link" />
              The website you want to redirect Passenger to.
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={buttons}
    />
  );

  const fourthStep = (
    <Screen
      header="What does Sender do?"
      childComponent={
        <StyledInfo>
          <div className="container">
            <span className="highlight">
              <b>2.</b>
            </span>
            &nbsp;&nbsp;Afterwards, you’ll be asked for the code shared by
            Passenger.
            <br />
            <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="circle-info" />
              Unless you used a shared link or scanned the QR Code generated by
              Passenger.
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={buttons}
    />
  );

  const fifthStep = (
    <Screen
      header="Who is the Passenger?"
      childComponent={
        <StyledInfo>
          <div className="container">
            <b>Passenger</b> is the device that is being redirected to another
            website.
            <br /> <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="tv" />
              <FontAwesomeIcon className="icon" icon="left-long" />
              <FontAwesomeIcon className="icon" icon="user" />
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={buttons}
    />
  );

  const sixthStep = (
    <Screen
      header="What does Passenger do?"
      childComponent={
        <StyledInfo>
          <div className="container">
            As the <b>Passenger</b>, you'll share a code or QR code with the
            device acting as Sender.
            <br />
            <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="key" />
              <FontAwesomeIcon className="icon" icon="right-long" />
              <FontAwesomeIcon className="icon" icon="user" />
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={buttons}
    />
  );

  const seventhStep = (
    <Screen
      header="End of Tutorial"
      childComponent={
        <StyledInfo>
          <div className="container">
            Thank you for taking the time to read our tutorial.
            <br />
            <br />
            <span className="highlight">
              <FontAwesomeIcon className="icon" icon="smile-beam" />
              We hope you enjoy using <b>web</b>pult!
            </span>
          </div>
        </StyledInfo>
      }
      message={{ customComponent: stepIndicator }}
      buttons={
        <>
          <Button
            text="Get Started"
            outline={true}
            onClick={() => navigate("/", { replace: true })}
          />
          {backButton}
        </>
      }
    />
  );

  const steps = [
    firstStep,
    secondStep,
    thirdStep,
    fourthStep,
    fifthStep,
    sixthStep,
    seventhStep,
  ];

  return steps[stepIndex];
};

export default Tutorial;
