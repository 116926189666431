import React from "react";
import StyledURLBox from "./styles/StyledURLBox.styled";

const URLBox = ({ url, submitHandler }) => {
  return (
    <StyledURLBox>
      <input
        className="box"
        type="text"
        value={url.value}
        onChange={(e) => url.set(e.currentTarget.value)}
        autoFocus={true}
        onKeyUp={(e) => submitHandler(e)}
        placeholder="Enter a website"
      />
    </StyledURLBox>
  );
};

export default URLBox;
