import styled from "styled-components";

const StyledButtonQR = styled.div`
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  transition: color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: bold;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  .disabled {
    color: ${({ theme }) => theme.colors.inActive};
  }

  .icon {
    margin-right: 0.5rem;
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;
export default StyledButtonQR;
