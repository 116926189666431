import React from "react";
import StyledButton from "./styles/StyledButton.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCopy,
  faRotateRight,
  faArrowUpRightFromSquare,
  faMugHot,
} from "@fortawesome/free-solid-svg-icons";

library.add(faCopy, faRotateRight, faArrowUpRightFromSquare, faMugHot);

function Button({
  text,
  icon = "",
  outline = false,
  dashed = false,
  cancel = false,
  disabled = false,
  onMouseEnter,
  onMouseLeave,
  onClick,
}) {
  return (
    <StyledButton
      outline={outline}
      dashed={dashed}
      cancel={cancel}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={disabled ? null : onClick}
    >
      {text}
      {icon && (
        <span className="icon">
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
    </StyledButton>
  );
}

export default Button;
