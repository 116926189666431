import styled from "styled-components";

const StyledButton = styled.div`
  user-select: none;
  border-radius: 0.25rem;
  width: 250px;
  height: 50px;

  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme, outline, cancel, disabled }) => {
    let color;
    if (outline) {
      if (disabled) color = theme.colors.inActive;
      else color = cancel ? theme.colors.red : theme.colors.primary;
    } else {
      color = theme.colors.background;
    }
    return color;
  }};

  background-color: ${({ theme, outline, cancel, disabled }) => {
    let bgColor;
    if (outline) bgColor = theme.colors.transparent;
    else if (cancel) bgColor = theme.colors.red;
    else if (disabled) bgColor = theme.colors.inActive;
    else bgColor = theme.colors.primary;
    return bgColor;
  }};

  outline: 0.125rem
    ${({ theme, outline, dashed, cancel, disabled }) => {
      let outlineColor;
      if (!outline) outlineColor = theme.colors.transparent;
      else if (cancel) outlineColor = theme.colors.red;
      else if (disabled) outlineColor = theme.colors.inActive;
      else outlineColor = theme.colors.primary;
      return dashed ? "dashed " : "solid " + outlineColor;
    }};

  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.9;
  }

  .icon {
    margin-left: 0.5rem;
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;
export default StyledButton;
