import StyledCodeBox from "./styles/StyledCodeBox.styled";
import { useRef } from "react";
import validator from "validator";

const CodeBox = ({ submitCodeHandler }) => {
  // References used for passcode fields
  const passCodeRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  // Returns all code box values joined
  const getCode = () => {
    let code = "";
    passCodeRefs.map((currentRef) => {
      code += currentRef.current.value;
      return 0;
    });
    return code;
  };

  const updateCode = () => {
    let code = getCode();
    if (code.length === passCodeRefs.length) {
      submitCodeHandler(code);
    }
  };

  // Creates a code box for each ref in array
  const passCodeFields = passCodeRefs.map((ref, i) => (
    <input
      key={i}
      ref={ref}
      className="box"
      autoFocus={i === 0}
      type="text"
      maxLength={1}
      onKeyDown={(e) => onKeyDownHandler(e, i)}
      onChange={(e) => onChangeHandler(e, i)}
      placeholder="-"
      onFocus={(e) => e.target.setSelectionRange(1, 1)}
      tabIndex={i + 1}
    />
  ));

  const onChangeHandler = (e, i) => {
    e.preventDefault();
    let val = e.nativeEvent.data;
    if (val && !validator.isEmpty(val)) {
      if (validator.isAlphanumeric(val)) {
        passCodeRefs[i].current.value = val;
        // Changes focus to next available input
        if (i !== passCodeRefs.length - 1) {
          passCodeRefs[i + 1].current.focus();
        }
      } else {
        passCodeRefs[i].current.value = "";
      }
    }
    updateCode();
  };

  /* Input Handlers */
  const onKeyDownHandler = (e, i) => {
    e.preventDefault();
    let nextRef;
    // Checks for backspace if field was already empty
    if (
      passCodeRefs[i].current.value.length === 0 &&
      e.nativeEvent.key === "Backspace"
    ) {
      // Changes focus to previous input and clears it
      if (e.target.value.length < 1) {
        // Checks if previous input exists
        if (i > 0) {
          nextRef = passCodeRefs[i - 1];
          nextRef.current.focus();
          nextRef.current.value = "";
        }
      }
    } else if (e.nativeEvent.key === "Backspace") {
      passCodeRefs[i].current.value = "";
    } else if (e.nativeEvent.key === "ArrowLeft" && i !== 0) {
      passCodeRefs[i - 1].current.focus();
    } else if (
      e.nativeEvent.key === "ArrowRight" &&
      i !== passCodeRefs.length - 1
    ) {
      passCodeRefs[i + 1].current.focus();
    } else {
      // Changes value if alphanumeric
      if (
        e.nativeEvent.key.length === 1 &&
        validator.isAlphanumeric(e.nativeEvent.key) &&
        !validator.isEmpty(e.nativeEvent.key)
      ) {
        passCodeRefs[i].current.value = e.nativeEvent.key;
        // Changes focus to next available input
        if (i !== passCodeRefs.length - 1) {
          passCodeRefs[i + 1].current.focus();
        }
      }
    }
    updateCode();
  };

  return (
    <StyledCodeBox>
      <div className="container">{passCodeFields}</div>
    </StyledCodeBox>
  );
};

export default CodeBox;
