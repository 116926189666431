import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import StyledStepIndicator from "./styles/StyledStepIndicator.styled";

library.add(faCircle);

const StepIndicator = ({ totalSteps, currentStep }) => {
  const indicator = [];
  for (let i = 0; i < totalSteps; i++) {
    indicator[i] = (
      <FontAwesomeIcon
        key={i}
        className={"icon " + (i === currentStep ? "active" : "")}
        icon="circle"
      />
    );
  }
  return <StyledStepIndicator>{indicator}</StyledStepIndicator>;
};

export default StepIndicator;
