import { useState } from "react";
import StyledDrawer from "../components/styles/StyledDrawer.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faTimes,
  faChalkboardTeacher,
  faFileShield,
  faMugHot,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

library.add(faHome, faTimes, faChalkboardTeacher, faFileShield, faMugHot);

const Drawer = ({ navigate, onClose }) => {
  const [currentAnimation, setCurrentAnimation] = useState({ right: "-260px" });
  const [isOpen, setIsOpen] = useState(false);

  const slideIn = () => {
    setCurrentAnimation({ right: "0px" });
  };

  const slideOut = () => {
    setCurrentAnimation({ right: "-260px" });
    setTimeout(() => {
      onClose();
    }, 500);
  };

  useEffect(() => {
    if (!isOpen) {
      slideIn();
      setIsOpen(true);
    }
  }, []);

  // Triggers slide out animation and closes drawer after 5s
  const onCloseHandler = () => {
    slideOut();
  };

  return (
    <StyledDrawer style={currentAnimation}>
      <div className="container">
        <div className="btn-close" onClick={onCloseHandler}>
          <FontAwesomeIcon icon="times" />
        </div>
        <div className="links">
          {/* <div
            className="link"
            onClick={() => {
              navigate("/", { replace: true });
              onCloseHandler();
            }}
          >
            <span className="link-icon">
              <FontAwesomeIcon icon="home" />
            </span>
            <span className="link-text">Home</span>
          </div> */}

          <div
            className="link"
            onClick={() => {
              navigate("/tutorial", { replace: true });
              onCloseHandler();
            }}
          >
            <span className="link-icon">
              <FontAwesomeIcon icon="chalkboard-teacher" />
            </span>
            <span className="link-text">How it Works</span>
          </div>
          <div
            className="link"
            onClick={() => {
              navigate("/privacy", { replace: true });
              onCloseHandler();
            }}
          >
            <span className="link-icon">
              <FontAwesomeIcon icon="file-shield" />
            </span>

            <span className="link-text">Privacy Policy</span>
          </div>
          <div
            className="link"
            onClick={() => window.open("https://www.buymeacoffee.com/medoware")}
          >
            <span className="link-icon">
              <FontAwesomeIcon icon="mug-hot" />
            </span>
            <span className="link-text">Buy us Coffee</span>
          </div>
        </div>
      </div>
    </StyledDrawer>
  );
};

export default Drawer;
