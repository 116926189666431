import styled from "styled-components";

const StyledURLBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    height: 2.5rem;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: bold;
    text-align: center;
    border-radius: 0.25rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    box-sizing: border-box;
    border: 0.1875rem solid ${({ theme }) => theme.colors.inActive};
    background-color: transparent;
    outline: none;
    color: ${({ theme }) => theme.colors.text};
  }

  .box:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .box::selection {
    background: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (min-width: 500px) {
    .box {
      width: 400px;
    }
  }
`;

export default StyledURLBox;
