import styled from "styled-components";

const StyledScreen = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .content-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-header {
  }

  .content-notification {
    height: ${({ theme }) => theme.fontSize.m};
  }

  .content-display {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 340px;
    max-width: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-message {
  }

  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .buttons {
    height: 120px;
    margin-top: 24px;
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
  }

  @media screen and (max-width: 520px) {
    .content-display {
      height: 38vh;
    }

    .buttons {
      flex-direction: column;
    }
  }
`;
export default StyledScreen;
