import { useState } from "react";

import Screen from "../containers/Screen";
import Button from "../components/Button";
import { useOutletContext } from "react-router-dom";

const Home = () => {
  const { navigate } = useOutletContext();
  const [image, setImage] = useState("empty.png");

  /* Button Handlers */
  const handleSender = () => {
    navigate("/sender", { replace: true });
  };

  const handlePassenger = () => {
    navigate("/passenger", { replace: true });
  };

  const buttons = (
    <>
      <Button
        text="Sender"
        outline={true}
        onMouseEnter={() => setImage("sender.png")}
        onMouseLeave={() => setImage("empty.png")}
        onClick={handleSender}
      />

      <Button
        text="Passenger"
        onMouseEnter={() => setImage("passenger.png")}
        onMouseLeave={() => setImage("empty.png")}
        onClick={handlePassenger}
      />
    </>
  );
  return (
    <Screen
      header="Who are you today?"
      image={image}
      message={{ text: "Select your role" }}
      buttons={buttons}
    />
  );
};

export default Home;
