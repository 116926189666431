import styled from "styled-components";

const StyledMessage = styled.div`
  height: ${({ theme }) => theme.fontSize.l};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 1.25rem;

  .container {
  }

  .tip {
    font-weight: bold;
  }
`;
export default StyledMessage;
