import styled from "styled-components";

const StyledCodeBox = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 0.375rem;
  }
  .box {
    height: 2.5rem;
    width: 2.5rem;
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: bold;
    text-align: center;
    border-radius: 0.25rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    box-sizing: border-box;
    border: 0.1875rem solid ${({ theme }) => theme.colors.inActive};
    background-color: transparent;
    outline: none;
    color: ${({ theme }) => theme.colors.text};
    /* color: transparent; */
    /* text-shadow: 0 0 0 ${({ theme }) => theme.colors.text}; */
  }

  .box:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  .box::selection {
    background: transparent;
  }

  @media screen and (min-width: 500px) {
    .container {
      width: 400px;
    }
    .box {
      height: 3rem;
      width: 3rem;
    }
  }
`;

export default StyledCodeBox;
