import React from "react";
import StyledMessage from "./styles/StyledMessage.styled";

const Message = ({ message }) => {
  const content = message.customComponent ? (
    message.customComponent
  ) : (
    <>
      {message.tip && <span className="tip">Tip: </span>}
      {message.text}
    </>
  );
  return (
    <StyledMessage>
      <div className="container">{content}</div>
    </StyledMessage>
  );
};

export default Message;
