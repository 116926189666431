import styled from "styled-components";

const StyledApp = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export default StyledApp;
