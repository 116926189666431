import React from "react";
import StyledInfo from "./styles/StyledInfo.styled";

const PrivacyPolicy = () => {
  return (
    <StyledInfo>
      <div className="container">
        <span className="text">
          We do not collect or share any data from our users.
        </span>
      </div>
    </StyledInfo>
  );
};

export default PrivacyPolicy;
