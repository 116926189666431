import { useState, useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import { BeatLoader } from "react-spinners";
import prependHttp from "prepend-http";

import Screen from "../containers/Screen";
import Button from "../components/Button";
import URLBox from "../components/URLBox";
import CodeBox from "../components/CodeBox";
import ButtonQR from "../components/ButtonQR";
import QRcode from "../components/QRcode";

const Sender = () => {
  const { code: linkCode } = useParams();

  const { navigate, domain } = useOutletContext();
  const [screenIndex, setScreenIndex] = useState(0);
  const [url, setUrl] = useState("");
  const [code, setCode] = useState("");
  const [notification, setNotification] = useState(null);

  // Sets code from params if valid
  useEffect(() => {
    if (linkCode && linkCode.length === 6) setCode(linkCode);
  }, []); // eslint-disable-line

  // Notification Handlers
  const invalidCodeNotificationHandler = () => {
    setNotification({
      text: "Invalid code, please try again.",
      isError: true,
      isPermanent: true,
    });
  };

  // Clears any existing notification
  const clearNotificationHandler = () => {
    if (notification) setNotification(null);
  };

  // Validation functions
  const validateURL = (u) => {
    if (ready !== validator.isURL(u)) {
      setReady(!ready);
    }
  };

  // Controls the active status of buttons
  const [ready, setReady] = useState(false);

  const setUrlHandler = (newUrl) => {
    setUrl(newUrl);
    validateURL(newUrl);
  };

  const submitCodeHandler = (inputCode) => {
    sendRequest(inputCode);
  };

  // Screen setters
  const screenIndexes = {
    url: 0,
    code: 1,
    wait: 2,
    success: 3,
    error: 4,
    qrCode: 5,
  };
  const showUrlScreen = () => {
    validateURL(url);
    setScreenIndex(screenIndexes.url);
  };
  const showCodeScreen = () => {
    setScreenIndex(screenIndexes.code);
  };
  const showWaitScreen = () => setScreenIndex(screenIndexes.wait);
  const showSuccessScreen = () => setScreenIndex(screenIndexes.success);
  const showErrorScreen = () => setScreenIndex(screenIndexes.error);
  const showQrCodeScreen = () => setScreenIndex(screenIndexes.qrCode);

  const isUrlScreen = () => screenIndex === screenIndexes.url;
  const isCodeScreen = () => screenIndex === screenIndexes.code;

  /* Request Handlers */
  const sendRequest = (validCode = code) => {
    axios.post(domain.api, { code: validCode, url }).then(({ data }) => {
      // 0: Success, 1: Error(code), 2: Error(other)
      switch (data.status) {
        case 0:
          showSuccessScreen();
          break;
        case 1:
          if (!validator.isEmpty(code)) setCode("");
          showCodeScreen();
          invalidCodeNotificationHandler();
          break;
        default:
          showErrorScreen();
      }
    });
    showWaitScreen();
  };

  /* Button Handlers */
  const cancelHandler = () => {
    navigate("/", { replace: true });
  };

  const backHandler = () => {
    clearNotificationHandler();
    setCode("");
    showUrlScreen();
  };

  const submitHandler = () => {
    clearNotificationHandler();
    // Checks if on codeScreen OR if theres no pending errors
    if (code) sendRequest();
    else showCodeScreen();
  };

  const inputSubmitHandler = (e) => {
    if (ready && e.key === "Enter") {
      submitHandler();
    }
  };

  // Handles donation button
  const coffeeHandler = () => {
    window.open("https://www.buymeacoffee.com/medoware");
  };

  /* Custom Components */

  const backButton = (
    <Button
      text={isCodeScreen() ? "Back" : "Cancel"}
      cancel={true}
      onClick={isCodeScreen() ? backHandler : cancelHandler}
    />
  );

  const buttons = (
    <>
      <Button
        text={isUrlScreen() && !code ? "Enter Code" : "Submit"}
        outline={true}
        disabled={!ready}
        onClick={submitHandler}
      />
      {backButton}
    </>
  );

  const successButtons = (
    <>
      <Button
        text={"Buy us Coffee"}
        icon="fa-mug-hot"
        outline={true}
        onClick={coffeeHandler}
      />

      <Button text="Home" onClick={() => navigate("/", { replace: true })} />
    </>
  );

  // Screens
  const urlScreen = (
    <Screen
      header="What is the destination URL?"
      notification={notification}
      childComponent={
        <URLBox
          url={{ value: url, set: setUrlHandler }}
          submitHandler={inputSubmitHandler}
        />
      }
      message={{
        customComponent: (
          <ButtonQR disabled={!ready} onClick={showQrCodeScreen} />
        ),
      }}
      buttons={buttons}
    />
  );

  const codeScreen = (
    <Screen
      header="Enter Passenger's Code"
      notification={notification}
      childComponent={<CodeBox submitCodeHandler={submitCodeHandler} />}
      message={{ text: "Get code from Passenger" }}
      buttons={backButton}
    />
  );

  const waitScreen = (
    <Screen
      childComponent={<BeatLoader color="#008080" />}
      buttons={<Button text="Cancel" cancel={true} onClick={cancelHandler} />}
    />
  );

  const successScreen = (
    <Screen
      header="Passenger launched!"
      notification={{ text: "Thanks for using webpult!", permanent: true }}
      image="sent.png"
      message={{ text: "Enjoyed our service?" }}
      buttons={successButtons}
    />
  );

  const errorScreen = (
    <Screen
      header="Something went wrong."
      notification={{
        text: "A server error occurred.",
        isError: true,
        isPermanent: true,
      }}
      image="rain.png"
      message={{ text: "Please try again later." }}
      buttons={
        <Button text="Home" onClick={() => navigate("/", { replace: true })} />
      }
    />
  );

  const qrCodeScreen = (
    <Screen
      header="Scan QR Code"
      notification={{
        text: prependHttp(url),
        isPermanent: true,
      }}
      childComponent={<QRcode value={prependHttp(url)} />}
      message={{ text: "Thanks for using webpult!" }}
      buttons={successButtons}
    />
  );

  const screens = [
    urlScreen,
    codeScreen,
    waitScreen,
    successScreen,
    errorScreen,
    qrCodeScreen,
  ];

  return screens[screenIndex];
};

export default Sender;
