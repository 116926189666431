import StyledScreen from "../components/styles/StyledScreen.styled";

import Header from "../components/Header";
import Notification from "../components/Notification";
import Display from "../components/Display";
import Message from "../components/Message";

const Screen = ({
  header,
  notification,
  image = null,
  childComponent = null,
  message = { text: "" },
  buttons,
}) => {
  return (
    <StyledScreen>
      <div className="content-container">
        <div className="content-header">
          <Header header={header} />
        </div>
        <div className="content-notification">
          {notification && <Notification notification={notification} />}
        </div>
        <div className="content-display">
          <Display imageName={image} childComponent={childComponent} />
        </div>
        <div className="content-message">
          <Message message={message} />
        </div>
        <div className="buttons-container">
          <div className="buttons">{buttons}</div>
        </div>
      </div>
    </StyledScreen>
  );
};

export default Screen;
