import React from "react";
import StyledButtonQR from "./styles/StyledButtonQR.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

library.add(faQrcode);
const ButtonQR = ({ disabled, onClick }) => {
  return (
    <StyledButtonQR onClick={!disabled ? onClick : null}>
      <div className={"container" + (disabled ? " disabled" : "")}>
        <span className="icon">
          <FontAwesomeIcon icon="fa-qrcode" />
        </span>
        <span className="text">Generate QR Code</span>
      </div>
    </StyledButtonQR>
  );
};

export default ButtonQR;
