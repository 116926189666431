import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Navbar from "./components/Navbar";
import { themes } from "./config/theme";

import StyledApp from "./components/styles/StyledApp.styled";
import Home from "./routes/Home";
import Sender from "./routes/Sender";
import Passenger from "./routes/Passenger";
import Tutorial from "./routes/Tutorial";
import Privacy from "./routes/Privacy";

const App = () => {
  /* Theme - default: light */
  const [theme, setTheme] = useState(themes.dark);
  const themeToggle = () => {
    setTheme(theme.isDarkMode ? themes.light : themes.dark);
  };
  let domain =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? {
          app: "http://localhost:3000/",
          api: "http://localhost:5000/",
        }
      : {
          app: "https://webpult.com/",
          api: "https://api.webpult.com/",
        };
  return (
    <ThemeProvider theme={theme}>
      <StyledApp>
        <Routes>
          <Route
            element={
              <Navbar
                context={{
                  theme: theme,
                  themeToggle: themeToggle,
                  navigate: useNavigate(),
                  domain: domain,
                }}
              />
            }
          >
            <Route path="/" element={<Home />} />

            <Route path="/sender" element={<Sender />} />
            <Route path="/s/:code" element={<Sender />} />

            <Route path="/passenger" element={<Passenger />} />

            <Route path="/tutorial" element={<Tutorial />} />
            <Route path="/privacy" element={<Privacy />} />

            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </StyledApp>
    </ThemeProvider>
  );
};

export default App;
