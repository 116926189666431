import styled from "styled-components";

const StyledNotification = styled.div`
  /* height: ${({ theme }) => theme.fontSize.m}; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryLight};
  margin-bottom: 1.25rem;

  .error {
    color: ${({ theme }) => theme.colors.red};
  }
`;
export default StyledNotification;
