import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Screen from "../containers/Screen";
import Button from "../components/Button";
import QRcode from "../components/QRcode";
import io from "socket.io-client";
import { BeatLoader } from "react-spinners";
import prependHttp from "prepend-http";

const Passenger = () => {
  const { navigate, domain } = useOutletContext();
  const [socket, setSocket] = useState(null);
  const [hasExpired, setHasExpired] = useState(false);
  const [code, setCode] = useState("");
  const [url, setUrl] = useState("");
  const [notification, setNotification] = useState(null);

  // Configures and returns socket.io socket
  const getSocket = () => {
    let newSocket = io(domain.api);

    newSocket.on("welcome", (payload) => {
      // console.log("Passenger Code: ", payload);
      setCode(payload);
    });

    // Redirects client
    newSocket.on("success", (res) => {
      let newURL = prependHttp(res);
      setUrl(newURL);
      setTimeout(() => redirect(newURL), 3000);
    });

    newSocket.on("expired", () => {
      setHasExpired(true);
    });

    return newSocket;
  };

  // Handlers for connecting and reconnecting to server
  const connect = () => {
    if (url) setUrl("");
    setSocket(getSocket());
  };

  const reconnect = () => {
    if (!hasExpired) setHasExpired(false);
    connect();
  };

  // Initial connection
  if (!socket) connect();

  // Handles notification component data
  const clipboardNotificationHandler = () => {
    // Prevents unecessary calls
    if (notification) return;
    setNotification({
      text: "Link copied!",
      onDestroy: () => setNotification(null),
    });
  };

  // Copies text to clipboard
  const copyHandler = (text) => {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    clipboardNotificationHandler();
  };

  // Disconnects client from server and returns to home
  const cancelHandler = () => {
    // disconnect socket
    if (socket) {
      socket.disconnect();
      // setSocket(null); ----------------------------------------------------
    }
    navigate("/", { replace: true });
  };

  // Redirects client in new tab
  const redirect = (u) => {
    window.open(u);
  };

  // Screens
  const loadingScreen = (
    <Screen
      //   header="Connecting to Server ..."
      inputComponent={<BeatLoader color="#008080" />}
    />
  );

  const waitingScreen = (
    <Screen
      header="Scan QR code"
      notification={notification}
      childComponent={<QRcode value={`${domain.app}s/${code}`} />}
      message={{ text: "Share code with Sender" }}
      buttons={
        <>
          <Button
            text={code}
            icon="copy"
            outline={true}
            dashed={true}
            onClick={() => copyHandler(`${domain.app}s/${code}`)}
          />
          <Button text="Cancel" cancel={true} onClick={cancelHandler} />
        </>
      }
    />
  );

  const successScreen = (
    <Screen
      header="Landing soon!"
      notification={{ text: "Redirecting in (3s)", isPermanent: true }}
      image="sent.png"
      message={{ text: "If not redirected, click below" }}
      buttons={
        <>
          <Button
            text="Redirect"
            icon="fa-arrow-up-right-from-square"
            outline={true}
            onClick={() => redirect(url)}
          />
          <Button text="Again" icon="fa-rotate-right" onClick={reconnect} />
        </>
      }
    />
  );

  const errorScreen = (
    <Screen
      header="Something went wrong."
      notification={{
        text: "Session has expired",
        isError: true,
        isPermanent: true,
      }}
      image="rain.png"
      message={{ text: "Please try again" }}
      buttons={
        <Button text="Restart" icon="fa-rotate-right" onClick={reconnect} />
      }
    />
  );

  // Returns the current screen to be displayed
  const getScreen = () => {
    if (hasExpired) return errorScreen;
    else if (url) return successScreen;
    else if (!code) return loadingScreen;
    else return waitingScreen;
  };

  return getScreen();
};

export default Passenger;
